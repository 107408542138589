var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"holder"},[_c('div',{staticClass:"top-holder"},[_c('p',{staticClass:"back-text",on:{"click":function($event){return _vm.backBtn()}}},[_vm._m(0),_vm._v("Back")]),_vm._m(1)]),_vm._m(2),_c('hr'),_vm._m(3),_c('hr'),_vm._m(4),_c('hr'),_vm._m(5),_c('hr'),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',{staticClass:"fa fa-chevron-left"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('button',{staticClass:"btn"},[_c('img',{attrs:{"src":require("../assets/icons/download-aggry.svg"),"alt":"download"}}),_vm._v("Doenload PDF")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-header"},[_c('h4',[_vm._v("Shelta Smart Partners - Tenancy Agreement")]),_c('p',[_vm._v("Agreement date: Mar 15, 2024")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"agreement-details"},[_c('h4',{staticClass:"text-header"},[_vm._v("LANDLORD DETAILS")]),_c('h6',[_vm._v("Name: "),_c('span',[_vm._v("Jane Joe")])]),_c('h6',[_vm._v("Email address: "),_c('span',[_vm._v("janedoe@gmail.com")])]),_c('h6',[_vm._v("Phone number: "),_c('span',[_vm._v("08036533234")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"agreement-details"},[_c('h4',{staticClass:"text-header"},[_vm._v("AGREEMENT TRERMS")]),_c('h6',[_vm._v("Revenue sharing: "),_c('span',[_vm._v("Shelta receives 10% of rental income")])]),_c('h6',[_vm._v("Agreement duration: "),_c('span',[_vm._v("5 years (March 2025 - March 2030)")])]),_c('h6',[_vm._v("Payment terms: "),_c('span',[_vm._v("Rent remitted annually on February of every year")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"agreement-details"},[_c('h4',{staticClass:"text-header"},[_vm._v("TERMINATION CLAUSE")]),_c('h6',[_vm._v("A 90 day notice is required for termination")]),_c('h6',[_vm._v("Revenue sharing: ")]),_c('h6',[_vm._v("Any outstanding payments will be "),_c('span',[_vm._v("settled within 30 days after termination")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-5"},[_c('h4',{staticClass:"text-header mb-3"},[_vm._v("SIGNATURES")]),_c('div',{},[_c('div',{staticClass:"signature-area"},[_c('div',{staticClass:"row"},[_c('h6',{staticClass:"col-6"},[_vm._v("Landlord: "),_c('span')]),_c('h6',{staticClass:"col-6"},[_vm._v("Date: "),_c('span')])])]),_c('div',{staticClass:"signature-area mt-3"},[_c('div',{staticClass:"row"},[_c('h6',{staticClass:"col-6"},[_vm._v("Shelta representative: "),_c('span')]),_c('h6',{staticClass:"col-6"},[_vm._v("Date: "),_c('span')])])])])])
}]

export { render, staticRenderFns }